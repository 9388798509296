import React from "react";

const PrivacyPolicyJA = () => {
  return (
    <div>
      <h1>SGAのプライバシーポリシー</h1>
      <p>
        SGA（以下「SGA」といいます。）が提供するウェブページ、ゲーム、情報及び関連サービスをご利用いただき、誠にありがどうございます。SGAは、個人情報及びプライバシー保護に努めております。
        SGAのゲームサービスを利用する場合、＜SGAのプライバシーポリシー＞（以下「本ポリシー」といいます。）の内容を確認し、同意いただく必要があります。SGAのゲームサービスを安心してご利用できるよう、以下のすべての条項をよくお読みください。
      </p>
      <h4>Ⅰ　本ポリシーの適用範囲</h4>
      <li>
        1.
        本ポリシーは、お客様から提供されたすべての個人情報、またはWebサイト、ソーシャルメディア、Eメール、メッセージングサービス、ゲームサービスを通じて収集した情報に適用されます。例えば、質問に回答する際に提供された個人情報、或いはサービスを利用する際にシステムによって自動的に収集された情報が含まれます。個人情報は必要最小限の範囲で収集の原則に基づき、SGAはお客様にゲームサービスを提供し、ニーズに応えるために、個人情報を最も限定的な方法で収集します。
      </li>
      <li>
        2.
        本ポリシーは、SGA以外の第三者が提供する外部ページには適用されません。また、SGAの委託先または管理者以外の第三者にも適用されません。その他、ゲームサービスのホームページには第三者のウェブページのリンクが含まれている可能性がありますが、その第三者には本ポリシーは適用されません。もしその第三者のプライバシーポリシーにご関心をお持ちである場合は、その第三者のプライバシーポリシーをご確認くださいますようお願いいたします。
      </li>
      <h4>Ⅱ　個人情報の収集、処理および利用方法</h4>
      <li>
        1.
        お客様がゲームサービスのアカウントを申請したり、ゲームサービスを利用したりする場合、イベントの性質に応じて必要な個人情報のご提供を求め、電磁的方法または書面等により、個人情報を処理または利用します。お客様の同意を得ない限り、個人情報を次項に定める利用目的以外に使用することはありません。
      </li>
      <li>
        2. 個人情報は以下の目的で利用されます。
        <ul className="list__level--2">
          <li>・SGAのゲームサービスの提供のため</li>
          <li>・本人確認及び認証サービスのため</li>
          <li>・料金請求、課金計算、返金処理のため</li>
          <li>・お客様からのお問合せに対応するため</li>
          <li>
            ・SGAのゲームサービスに関する通知及び本ポリシーの変更等、必要に応じたご連絡を行うため
          </li>
          <li>・アンケートの実施のため</li>
          <li>・懸賞、キャンペーンの実施及び商品発送のため</li>
          <li>・当社及び第三者の商品、サービスの広告または宣伝のため</li>
          <li>・ポイントサービス提供のため</li>
          <li>・マーケティング調査、統計、データ分析のため</li>
          <ul className="list__level--3">・SGAのゲームサービスの品質向上のため</ul>
          <li>・新しいサービス、新機能の開発及び提供のため</li>
          <ul className="list__level--3">・システムのメンテナンス、不具合対応のため</ul>
          <li>・不正利用防止のため</li>
          <li>・その他SGAの各サービスにおいて個別に定める目的のため</li>
        </ul>
      </li>
      <li>
        3.
        個人情報とは、特定の個人を直接的または間接的に識別できる情報のことです。メールボックスサービス、アンケート、登録アカウント、メッセージ、支払い等のインタラクティブ機能付きのサービスを利用する場合、お客様より提供された氏名、アカウント名、Eメールアドレス、連絡先情報、生年月日、性別、利用時間、場所、IPアドレス、ゲームのログ、クッキー（ユーザー端末に保存されているデータであり、通常、それ自体で直ちに個人を識別することはできません。）、ログインデバイスの端末情報、ソーシャルメディア情報（例えば個人のFacebookまたはTwitterアカウントを使用し、ログインまたはゲームサービスへアクセスする場合）、口座、取引情報等をSGAは保存します。サーバーは、一般的に、アクセスするたびに自動的に、利用されたデバイスのIPアドレス、利用時間、利用オペレーティングシステム、閲覧及びクリックの関連操作等を記録し、これらのデータは、ゲームサービスの向上のための参考としてSGAの内部で使われます。
      </li>
      <li>
        4.
        SGAは、広告の提供及びデータ分析のため、取得したIPアドレス、クッキー、端末情報、位置情報及びウェブサイト内の行動履歴情報をFacebook及びGoogleに提供することがあります。
      </li>
      <li>
        5.
        テクニカルサポートに関するお問合せをくださった場合、更なる個人情報の提供を求めたり、ご使用のデバイスからお客様のゲームサービスの利用履歴を取得することがあります。
      </li>
      <li>
        6.
        SGAは、ゲームサービスを通じて利用者の行動に関わる情報を収集することがあります。当該情報は直ちに利用者を識別することができません。当該情報は、通常の方法によっては個人情報を復元できない形式にて、ビッグデータ分析のために、SGAにて使用されることがあります。
      </li>
      <li>
        7.
        お客様ご提供の個人情報がご本人のものでない場合、お客様は、ご本人が本ポリシーのすべての内容を理解し、同意し、当該個人情報をSGAに提供することを授権していることを保証しなければなりません。
      </li>
      <li>
        8.
        SGAが必要とする個人情報の提供を拒否した場合、または最新かつ正確な個人情報を提供しなかった場合、ゲームサービスを利用する権利に影響を及ぼし、ゲームサービスを利用できなくなる恐れがあります。
      </li>
      <li>
        9.
        SGAは、お客様の興味に見合ったサービスを提供するために、SGAのウェブサイトの利用状況等を把握し、統計を取る目的でクッキーを利用することがあります。お客様はクッキーを拒否する設定をすることができますが、この場合、SGAの提供するサービスの全部または一部が利用できなくなる場合があります。
      </li>
      <h4>Ⅲ　制限行為能力者に関する規定</h4>
      <li>
        1.
        SGAの提供するゲームやサービスは「成人指定」であり、18歳未満の方の使用や閲覧は禁止されています。また、故意に制限行為能力者（未成年者を含む）から個人情報を収集することはありません。お客様が制限行為能力者である場合は、速やかに本サイトを離れ、ダウンロードしたゲームや関連するデータを全て削除してください。個人情報は必要最小限の範囲で収集し、ご提供いただいた年齢を確認したり、それを検証したりすることはありません。
      </li>
      <li>
        2.
        制限行為能力者から個人情報を収集したと考えた場合、その保護者等は、個人情報の削除をメールで請求することができます。
      </li>
      <h4>Ⅳ　個人情報の保護</h4>
      <li>
        1.
        SGAは、管理上および技術上の措置を講じ、個人情報が無許可のアクセス、使用、変更、開示または廃棄から免れるよう努めます。SGAのメインフレームには、ファイアウォールやウイルス対策システム等の各関連情報の安全設備及び必要な安全防護措置が設けられ、ウェブサイト及び個人情報を厳重に保護します。授権された人員しか個人情報にアクセスすることができず、すべての関係者は秘密保持契約を締結しています。もし彼らが秘密保持義務に違反した場合、法律による処分を受け、または契約責任の追及がなされます。業務上の必要に応じ、サービスを提供するために第三者に業務を委託する必要がある場合、SGAは秘密保持義務を厳格に遵守し、確実に遵守することを保証するために必要な検査を行います。
      </li>
      <li>
        2.
        SGAは、ネットワーク及びシステムの完全性及び安全性を確保するよう力を尽くしていますが、セキュリティー対策が第三者のハッカーによる情報への不正アクセスを完全に防ぐことを保証することはできません。いかなるインターネットの送信方法や電磁的な保存方法も100％安全ではありません。したがって、第三者が情報を悪用しお客様に損害を被らせることがないと保証することはできません。
      </li>
      <li>
        3.
        SGAは、本ポリシーの範囲内で、ゲームサービスを提供する期間中、個人情報を保存し使用します。法律制度、税務、会計等のために必要となる場合、お客様の個人情報を一定期間保存することもあります。個人情報を使用する目的が既に存在しない場合、個人情報を削除し、また個人を識別できないように匿名化します。
      </li>
      <h4>Ⅴ　当事者の権利</h4>
      <li>
        1.
        個人情報について、当社の連絡先に対して電子メールでご請求いただくことにより、以下の権利を行使することができますが、そのために必要な料金を請求することがあります。
        <ul className="list__level--2">
          <li>（A）閲覧の照会または請求。</li>
          <li>（B）コピーの請求。</li>
          <li>（C）補正または訂正の請求。</li>
          <li>（D）収集、処理または利用停止の請求。</li>
          <li>（E）削除の請求。</li>
        </ul>
      </li>
      <li>2. 適用される法令等に従い、前項の請求を拒絶することもあります。</li>
      <h4>Ⅵ　第三者との共同利用</h4>
      <li>
        1.
        個人情報を他の個人、団体、民間企業または公務機関に提供、交換、貸与または販売することはありません。ただし、Ⅱ.4項で定める場合及び次項の場合を除きます。前項のただし書きには、以下を含みますが、これに限りません。
        <ul className="list__level--2">
          <li>（A）書面によるお客様の同意がある場合 </li>
          <li>（B）法律上の明文規定がある場合</li>
          <li>
            （C）国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に遂行に影響を及ぼすおそれがある場合
          </li>
          <li>
            （D）お客様の生命、身体、自由または財産の危険を避けるために必要であって、本人の同意を得ることが困難である場合
          </li>
          <li>
            （E）他人の権益の重大な危害を防止するために必要であって、本人の同意を得ることが困難である場合
          </li>
        </ul>
      </li>
      <li>
        2.
        SGAのウェブページまたはアプリケーションは、第三者のウェブページまたはアプリケーションへのリンクを提供することがあり、お客様は、このリンクにより他のウェブサイトにアクセスすることはできますが、そのリンクされたサイトには本ポリシーには適用されません。そのリンク先のウェブサイトまたはアプリケーションのプライバシーポリシーをご参照ください。
      </li>
      <h4>Ⅶ　本ポリシーの改訂</h4>
      本ポリシーは必要に応じ、修正または更新され、改訂されたポリシーは、ホームぺージ上またはゲーム開始時に表示されます。十分な権利保護のために、本ポリシーの最新版を定期的にご覧ください。お客様がSGAの提供するゲームサービスを引き続き使用したときは、本ポリシーの最新版に同意したものとします。
      <h4>Ⅷ　連絡先</h4>
      本ポリシーまたはSGAによる対応についてご質問がある場合は、service@sg-arts.com
      までご連絡ください。
    </div>
  );
};

export default PrivacyPolicyJA;
