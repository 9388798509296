import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import PrivacyPolicyZHHant from "./PrivacyPolicyZHHant";
import PrivacyPolicyZHHans from "./PrivacyPolicyZHHans";
import PrivacyPolicyEN from "./PrivacyPolicyEN";
import PrivacyPolicyJA from "./PrivacyPolicyJA";
import { BgWrapper, PageWrapper, ContentWrapper } from "../../components";

export default function PrivacyPolicyContent() {
  const intl = useIntl();
  const { locale } = intl;

  const getContent = () => {
    switch (locale) {
      case "zh-Hant":
        return <PrivacyPolicyZHHant />;
      case "zh-Hans":
        return <PrivacyPolicyZHHans />;
      case "ja":
        return <PrivacyPolicyJA />;
      case "ko":
      case "en":
      default:
        return <PrivacyPolicyEN />;
    }
  };

  return (
    <BgWrapper>
      <PageWrapper>
        <ContentWrapper>{getContent()}</ContentWrapper>
      </PageWrapper>
    </BgWrapper>
  );
}
