import React from "react";

const PrivacyPolicyEN = () => {
  return (
    <div>
      <h1>SGA Personal Information and Privacy Policy</h1>
      <p>Dear User,</p>
      <p>
        Thank you for approaching our game and service. We, SGA, and our affiliates (hereinafter
        “SGA”, “us”, “we” or “our”) dedicate to protect your personal information and privacy while
        providing the best quality games, services, website and information (together hereinafter
        “Game Services”). In order for you (hereinafter “you” or “User”) to use our Game Services,
        we need to process some of your data. Before you begin to use SGA’s Game Services, please
        read the following details and explanation of the ＜SGA Personal Information and Privacy
        Policy＞ (hereinafter “Policy”) carefully. By using the Game Services, you are expressing
        your agreement to this Policy and the processing of your data, including your personal
        information, in the manner provided in this Policy. If you do not agree to any of these
        terms, please do not use the Game Services. Thank you for your cooperation.
      </p>
      <h4>1. The Scope of Policy</h4>
      <li>
        1.1 This Policy applies to all personal information you provide to us and all information we
        collect through our website, Game Services, any social media and email; including but not
        limited to personal information you give us when you report a problem to Game Services and
        data collected automatically about your activity as a user of Game Services. In accordance
        with the minimization principle of collection, we will only collect personal information to
        the extent necessary to provide you with the Game Services and fulfil your requests.
      </li>
      <li>
        1.2 This Policy does not apply to the website links to other related sites excepting SGA’s
        website, it also does not apply to any other third-party who is not entrusted or engaged by
        SGA.
      </li>
      <h4>2. Collection, Processing and Use of Personal Information</h4>
      <li>
        2.1 In order to offer you the best interactive Game Services, we may ask you to provide
        necessary personal information, and we may collect and use such personal information in
        electronic or non-electronic manner worldwide for specified purposes relating to our
        business operation. We will not collect or use your personal information for purposes other
        than specified purposes unless otherwise agreed by you.
      </li>
      <li>
        2.2 Your personal information will be collected and used for the following purposes:
        identification and authentication, account access and account management, organization and
        synchronization of information between your different accounts, providing gaming records to
        your inquiry, contact, provision of products, provision of maintenance, push notifications,
        providing marketing information, notifying about the change to Game Services, payment
        verification and confirmation, making refund, guarantee and other services you have
        requested, provision of information relevant to your chosen products and services, provision
        of advertising and promotional materials by our company, improvement of services, conducting
        surveys, fulfillment of any contractual obligations or the like, research and analysis and
        reporting, any legal or accounting purpose, and any other purpose related to our business
        operation.
      </li>
      <li>
        2.3 Personal information is information that is practicable for identifying the individual
        to be directly or indirectly ascertained. We may collect and preserve your data or personal
        information such as your name, email address, contact information, account name, the times
        you use our Game Services, birthday, gender, your location, IP address, Cookies (a small bit
        of record-keeping information that web sites often store on a user's computer. Cookies are
        not intended to include people's names, or other information that is directly identifiable),
        technical details about any device which you use to access Game Services, details about your
        use of social networking and Game Services (for example, if you have linked the Game
        Services to your social networking account like Facebook, twitter and the like, then we may
        acquire some of your information through your social networking account), data about your
        use of the Game Service, such as gameplay data and your interactions with other players
        inside the Game Service, account and trade information while you are using the interactive
        Game Services including but not limited to sending email to our service department, filling
        out questionnaires, applying to open an account, leaving a message, or paying for your
        purchases. We may provide your personal information to our business partners if it is
        necessary for our business operations (including but not limited to the Game Services’
        distributor or publisher, credit card company, iOS or Android platform company, Facebook,
        Twitter and other social media) and we may also obtain your personal information from our
        business partners if necessary to achieve a specified purpose. In general, the server
        automatically records some information and relevant act, including but not limited to your
        IP address using the connection device, the use of time, a browser and select data records,
        as we enhance the Game Services reference. These records are only for internal use and may
        not be able to identify a specific person. In addition to internal research, we will publish
        statistical data as needed, but such publication does not involve specific personal
        information.
      </li>
      <li>
        2.4 If you contact our technical or customer support, we may process other data required to
        help you with any queries or support matters, such as data collected in crash logs that are
        gathered by your device or the technical parameters of the device you use to play.
      </li>
      <li>
        2.5 We might process some aggregated and general non-personal data on user behavior (e.g.
        sales per region) with third party partners who work with us to provide Game Services to you
        in order to support, improve or amend Game Services. We may also share non-personal data
        with data analysis services to help us run and improve Game Services.
      </li>
      <li>
        2.6 If you are providing personal information other than your own, then you must guarantee
        that such person acknowledges and agrees to all the terms and conditions of this Policy and
        agrees you to provide his or her personal information to us.
      </li>
      <li>
        2.7 If you refuse to provide us your up to date and correct personal information when
        required, you may be refused access to the Game Services temporarily or permanently.
      </li>
      <h4>3. Policy on Person Who is Limited in Capacity to Make Juridical Acts</h4>
      <li>
        3.1 The Game Services we provided are rated R, which are suitable only for adults ages 18
        and up. We do not knowingly collect or solicit personal information from persons who are
        limited in or without capacity to make juridical acts. If you are a person who is limited in
        or without capacity to make juridical acts, please leave this website and delete all the
        Game Services or records you have downloaded. Subject to the rules of minimum extent of
        collection of personal information, we will not take any actions to verify the age you have
        provided us.
      </li>
      <li>
        3.2 If you are a guardian and you believe that we might have collected personal information
        from a person who is limited in or without capacity to make juridical acts whom you have
        responsibility for and would like to request us to delete the personal information of such
        person, please contact us by email.
      </li>
      <h4>4. Protection on Personal Information and Data</h4>
      <li>
        4.1 The data SGA collect from you will stored on our secure servers. SGA will use reasonable
        commercial endeavors, including administrative and technical measures, to safeguard your
        personal information from unauthorized access, use, alteration, disclosure or destruction.
        All of our servers are equipped with firewalls, antivirus systems and other related
        information security equipment and the necessary security precautions to protect the website
        and your personal information. We restrict access to personal information, only authorized
        person who are subject to strict contractual confidentiality obligations may access to your
        personal information, and such authorized person may be disciplined or terminated if they
        fail to meet these obligations.
      </li>
      <li>
        4.2 Although we make good faith efforts to store the information collected on the service in
        a secure operating environment that is not available to the public, we cannot guarantee the
        absolute security of the information during its transmission or its storage on our systems.
        Further, while we attempt to ensure the integrity and security of our network and systems,
        we cannot guarantee that our security measures will prevent third-party "hackers" from
        illegally obtaining access to this information. We do not warrant or represent that your
        information will be protected against, loss, misuse, or alteration by third parties. No
        method of transmission over the internet, or method of electronic storage, is 100% secure
        therefore, we cannot guarantee its absolute security.
      </li>
      <li>
        4.3 We will retain your personal information only for as long as needed in order to fulfill
        the purposes outlined in this Policy. In certain special cases, a longer retention period
        might be required by law, such as for tax reasons, accounting purposes or other legal
        requirements and obligations. When we will no longer require your personal information in
        order to provide Game Services, we will either delete it or anonymize it.
      </li>
      <h4>5. Rights on Personal Information</h4>
      <li>
        5.1 You may exercise the following rights by providing us written notification, however, we
        may charge you necessary fees in regards to your request:
        <ul className="list__level--2">
          <li>A. any inquiry and request for a review of the personal information;</li>
          <li>B. any request to make duplications of the personal information;</li>
          <li>C. any request to supplement or correct the personal information;</li>
          <li>
            D. any request to discontinue collection, processing or use of personal information; and
          </li>
          <li>E. any request to delete the personal information.</li>
        </ul>
      </li>
      <li>
        5.2 We may reject your request if it conflicts with our legal or contractual obligations.
      </li>
      <h4>6. Policy on Sharing Personal Information with Third Party</h4>
      <li>
        6.1 We will not provide, exchange, lease or sell your personal information to other persons,
        organizations, private corporations or public institutions, unless required due to legal or
        contractual obligations.
      </li>
      <li>
        6.2 The “legal or contractual obligations” referred in the preceding paragraph includes but
        is not limited to the following circumstances:
        <ul className="list__level--2">
          <li>A. with your consent;</li>
          <li>B. when in accordance with law;</li>
          <li>C. when it is for national security or to promote public interests;</li>
          <li>D. when it is to prevent harm on the life, body, freedom or property of you;</li>
          <li>E. in order to avoid serious damage to any other person;</li>
          <li>F. when such use may benefit you.</li>
        </ul>
      </li>
      <li>
        6.3 Our website and Game Services may content links to a third-party’s website or software;
        you may connect to such third-party’s website or software by clicking the link. However,
        this Policy does not apply to such third-party website so you must read the personal
        information protection policy of such third party to protect your own personal information.
      </li>
      <h4>7. International Data Transfer</h4>
      Our Game Services is mainly provided through internet; hence it is will be available worldwide
      by its nature. Your data can therefore be transferred to anywhere in the world. Because
      different countries may have different data protection laws than your own country, we take
      steps to ensure adequate safeguards are in place to protect your data as explained in this
      Policy.
      <h4>8. Changes and Updates to this Policy</h4>
      We may occasionally update this Policy to reflect changes in our company’s service and
      customers’ feedback; the amended terms will be published at the beginning of each game. We
      reserve the right to change the terms, conditions and notices under this Policy, with or
      without notifications to you. We encourage you to periodically review this Policy to be
      informed of how we are protecting your information and rights. You are responsible to read and
      abide by the latest version of the Policy. Your continued use of the products, websites and
      Game Services provided by us constitutes your agreement to the latest updated Policy and any
      updates.
      <h4>9. Language</h4>
      This Policy may be translated into different languages for your reference only; if there is
      any inconsistency or ambiguity between the Chinese version and the other language’s version,
      the Chinese version shall prevail.
      <h4>10. Contact Information</h4>
      If you have any enquiries or concerns about this Policy or our practice, please contact us by
      email at service@sg-arts.com.
      <p>Last updated: September 16, 2020</p>
    </div>
  );
};

export default PrivacyPolicyEN;
