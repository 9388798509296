import { useState, useEffect } from "react";

const useAgeConfirmed = () => {
  const [ageConfirmed, setAgeConfirmed] = useState(true);

  useEffect(() => {
    // get cookie
    let cookie_value;
    if (typeof window !== "undefined") {
      cookie_value = window.document.cookie
        .split("; ")
        .find(row => row.startsWith("age_verified="));
    }

    // set initial has_confirmed value
    setAgeConfirmed(!!cookie_value);
  }, [setAgeConfirmed]);

  const handleConfirm = () => {
    setAgeConfirmed(true);
    // the age_verified cookie will be cleared after 24 hours
    if (typeof window !== "undefined")
      window.document.cookie = `age_verified=true;max-age=${
        60 * 60 * 24
      };path=${"/"};SameSite=Strict;`;
  };

  const handleDecline = () => {
    if (typeof window !== undefined) {
      window.location.href = "https://www.google.com/";
    }
  };

  return {
    ageConfirmed,
    handleConfirm,
    handleDecline,
  };
};

export default useAgeConfirmed;
