import { useState, useEffect } from "react";

const useLoaded = (delay = 2000) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let loading = null;
    if (typeof window !== "undefined") {
      loading = window.setTimeout(() => {
        setLoaded(true);
      }, delay);
    }

    return () => {
      window.clearTimeout(loading);
    };
  }, [delay]);

  return { loaded };
};

export default useLoaded;
