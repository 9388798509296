import React from "react";

const PrivacyPolicyZHHant = () => {
  return (
    <div>
      <h1>SGA個人資料暨隱私政策條款</h1>
      <p>親愛的使用者您好：</p>
      <p>
        歡迎您使用SGA（以下稱「我們」或「SGA」）所提供的網頁、遊戲、資訊以及相關服務（以下稱「遊戲服務」）。SGA致力於保護您的個人資料及隱私權，為了讓您能夠安心地使用我們提供的各項遊戲服務，請您詳閱本＜SGA個人資料暨隱私政策條款＞（以下稱「本條款」），使用我們的遊戲服務就代表著您已閱畢並同意以下內容。如果您不同意這些條款，請不要使用遊戲服務，謝謝您的配合。
      </p>
      <h4>一、本條款適用範圍</h4>
      <li>
        1.
        本條款適用於所有您提供的個人資料或者我們通過網站、社群媒體、電子郵件、訊息或遊戲服務收集到的個人資料，例如您回報問題時所提供的個人訊息或使用遊戲服務時系統自動蒐集的資訊。基於最小蒐集原則，我們只會在提供您遊戲服務及回應您需求的目的下，以最有限的方式蒐集您的個人資料。
      </li>
      <li>
        2.
        本條款不適用於SGA以外第三方所提供之外部網頁，亦不適用於SGA所委託或參與管理的人員之外之第三方。此外，在遊戲服務的頁面中可能包含第三方網頁之連結，該第三方並不適用本條款，如果您想知道該第三方的隱私權保護政策，請逕行查詢該第三方之隱私權保護條款。
      </li>
      <h4>二、個人資料的蒐集、處理及利用方式</h4>
      <li>
        1.
        當您申請遊戲服務之帳號或使用遊戲服務時，我們將視活動性質請您提供必要的個人資料，並在特定目的範圍內以電子或非電子化方式不限地域處理及利用您的個人資料；非經您同意，我們不會將個人資料用於特定目的以外其他用途。
      </li>
      <li>
        2.
        您的個人資料將用於以下目的：身份識別和認證、帳戶登入和帳戶管理、您的不同帳號資訊整合及同步、提供遊戲紀錄查詢、聯繫、提供產品、提供市場訊息、通知有關遊戲服務之變更、提供維護、推送通知、帳務處理、提供有關您的產品和服務的資料、提供您廣告和宣傳材料、改善服務品質、調查、研究、分析和報告、法律規定或會計需求之用途，以及其他SGA營運相關用途、行銷、契約或類似契約法律關係。
      </li>
      <li>
        3.
        個人資料是指可用以直接或間接地辦識特定個人身份的資料。我們在您使用客服信箱、問卷調查、註冊帳號、留言、付款等互動性功能時，會保留您所提供的姓名、帳號名稱、電子郵件地址、聯絡方式、生日、性別、使用時間、地理位置、IP位址、Cookies
        （儲存於用戶端之資料，通常無法直接用於連結個人身份）、登入設備裝置資訊、社群媒體資訊（例如若您使用您的Facebook或twitter帳戶登入或連結遊戲服務）、帳戶及交易訊息等。因業務經營之必要我們可能將您的個人資料提供給與我們有業務之合作夥伴（例如遊戲服務經銷商、信用卡公司、蘋果(iOS)或Google(Android)或Facebook等社群媒體）或自該等合作夥伴處取得您的個人資料以達到前述特定目的。於一般連線時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的作業系統、瀏覽及點選資料記錄等，做為我們增進遊戲服務的參考依據，此記錄為內部應用。
      </li>
      <li>
        4.
        若您為了尋求技術支援而聯繫我們，我們也許會向您要求提供其他個人資料或自您使用的裝置、設備中取得您使用遊戲服務的紀錄。
      </li>
      <li>
        5.
        我們可能會透過遊戲服務蒐集使用者行為資訊，該等資訊無法直接用於辨識特定使用者身份，但將可能被SGA及其合作夥伴用於大數據分析。
      </li>
      <li>
        6.
        倘您並非您所提供的個人資料之當事人，您必須保證該當事人已了解並同意本條款所有內容並授權您提供我們其個人資料。
      </li>
      <li>
        7.
        若您拒絕提供我們所需的個人資料或未提供最新且正確之個人資料，將可能影響您使用遊戲服務之權益或導致您無法使用遊戲服務。
      </li>
      <h4>三、限制行為能力人相關政策</h4>
      <li>
        1.
        我們提供的遊戲服務屬於限制級，未滿18歲之人不得以任何方式使用或觀覽。我們不會明知而蒐集無行為能力人或限制行為能力人（包括未成年人）的個人資料。如果您是無行為能力人或限制行為能力人，請立即離開本網頁並刪除您已經下載的遊戲服務或相關紀錄。基於個人資料最小蒐集原則，我們不會主動審核或驗證您提供的年齡。
      </li>
      <li>
        2.
        如果您是監護人且您相信我們蒐集了受您監護之人的個人資料，您可以透過電子郵件與我們聯繫要求刪除該等個人資料。
      </li>
      <h4>四、資料之保護</h4>
      <li>
        1.
        SGA將盡合理的商業努力，包括行政和技術措施，以保障您的個人資料免受未經授權的接觸、使用、變更、披露或銷毀。我們的主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以嚴格保護網站及您的個人資料，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分或合約責任追究。如我們因業務需要有必要委託業務往來第三方提供服務時，我們亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
      </li>
      <li>
        2.
        雖然我們致力於確保我們網路及系統的完整性與安全性，但我們無法保證我們的安全措施能夠完全防止第三方駭客非法獲得訪問此等資訊的許可權。任何互聯網傳輸方法或電子儲存方法都不是100%安全的。因此，我們不能保證您的資訊絕對不會受到第三方濫用而產生任何的損失。
      </li>
      <li>
        3.
        我們會在符合本隱私條款及提供您遊戲服務之目的及期間內保存及使用您的個人資料。為符合法制、稅務、會計需求等，我們可能會將您的資料保存一定的期間。當使用您的個人資料的目的已不復存在時，我們將會刪除您的個人資料或將您的個人資料匿名化，使其不具辨別身份之效力。
      </li>
      <h4>五、當事人權利</h4>
      <li>
        1. 您得就您的個人資料以書面方式向我們行使之下列權利，但我們可能因此向您酌收必要之費用：
        <ul className="list__level--2">
          <li>(A) 查詢或請求閱覽。</li>
          <li>(B) 請求製給複製本。</li>
          <li>(C) 請求補充或更正。</li>
          <li>(D) 請求停止蒐集、處理或利用。</li>
          <li>(E) 請求刪除。</li>
        </ul>
      </li>
      <li>2. 為履行法律或契約上義務，我們可能會拒絕您依前項所為之請求。</li>
      <h4>六、與第三人共用個人資料之政策</h4>
      <li>
        1.
        我們絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
      </li>
      <li>
        2. 前項但書之情形包括但不限於：
        <ul className="list__level--2">
          <li>(A) 經由您書面同意。</li>
          <li>(B) 法律明文規定。</li>
          <li>(C) 為維護國家安全或增進公共利益。</li>
          <li>(D) 為免除您生命、身體、自由或財產上之危險。</li>
          <li>(E) 為防止他人權益之重大危害。</li>
          <li>(F) 其他有利於您的權益之情形。</li>
        </ul>
      </li>
      <li>
        3.
        SGA的網頁或應用程式中可能提供第三方網站或應用程式的連結，您可經由該連結點選進入其他網站，但該連結網站不適用本條款，您必須參考該連結網站或應用程式的隱私權保護政策。
      </li>
      <h4>七、隱私權保護政策之修正</h4>
      本條款將因應需求隨時進行修正或更新，修正後的條款將在遊戲開始時顯示。請您定期閱讀和遵守本條款最新版本以充分保障您的權利。如您繼續使用我們所提供的遊戲服務，即表示您同意本條款之最新版本。
      <h4>八、語言</h4>
      如中文版本和其他語言的版本有不一致的地方，一切皆以中文版本為準。
      <h4>九、聯絡方式</h4>
      如果您對於本條款或SGA的隱私保護政策或行為有任何疑問，歡迎透過電子郵件service@sg-arts.com與我們聯繫。
      <p>最後更新日期：2020年09月16日</p>
    </div>
  );
};

export default PrivacyPolicyZHHant;
