import React from "react";

const PrivacyPolicyZHHans = () => {
  return (
    <div>
      <h1>SGA个人资料暨隐私政策条款</h1>
      <p>亲爱的使用者您好：</p>
      <p>
        欢迎您使用SGA（以下称「我们」或「SGA」）所提供的网页、游戏、信息以及相关服务（以下称「游戏服务」）。SGA致力于保护您的个人资料及隐私权，为了让您能够安心地使用我们提供的各项游戏服务，请您详阅本＜SGA个人资料暨隐私政策条款＞（以下称「本条款」），使用我们的游戏服务就代表着您已阅毕并同意以下内容。如果您不同意这些条款，请不要使用游戏服务，谢谢您的配合。
      </p>
      <h4>一、本条款适用范围</h4>
      <li>
        1.
        本条款适用于所有您提供的个人资料或者我们通过网站、社群媒体、电子邮件、讯息或游戏服务收集到的个人资料，例如您回报问题时所提供的个人讯息或使用游戏服务时系统自动搜集的信息。基于最小搜集原则，我们只会在提供您游戏服务及响应您需求的目的下，以最有限的方式搜集您的个人资料。
      </li>
      <li>
        2.
        本条款不适用于SGA以外第三方所提供之外部网页，亦不适用于SGA所委托或参与管理的人员之外之第三方。此外，在游戏服务的页面中可能包含第三方网页之连结，该第三方并不适用本条款，如果您想知道该第三方的隐私权保护政策，请径行查询该第三方之隐私权保护条款。
      </li>
      <h4>二、个人资料的搜集、处理及利用方式</h4>
      <li>
        1.
        当您申请游戏服务之账号或使用游戏服务时，我们将视活动性质请您提供必要的个人资料，并在特定目的范围内以电子或非电子化方式不限地域处理及利用您的个人资料；非经您同意，我们不会将个人资料用于特定目的以外其他用途。
      </li>
      <li>
        2.
        您的个人资料将用于以下目的：身份识别和认证、账户登入和账户管理、您的不同帐户信息整合及同步、提供游戏纪录查询、联系、提供产品、提供市场信息、通知有关游戏服务之变更、提供维护、推送通知、帐务处理、提供有关您的产品和服务的数据、提供您广告和宣传材料、改善服务质量、调查、研究、分析和报告、法律规定或会计需求之用途，以及其他SGA营运相关用途、营销、契约或类似契约法律关系。
      </li>
      <li>
        3.
        个人资料是指可用以直接或间接地办识特定个人身份的资料。我们在您使用客服信箱、问卷调查、注册账号、留言、付款等互动性功能时，会保留您所提供的姓名、账号名称、电子邮件地址、联络方式、生日、性别、使用时间、地理位置、IP地址、Cookies
        （储存于客户端之数据，通常无法直接用于连结个人身份）、登入设备装置信息、社群媒体信息（例如若您使用您的Facebook或twitter账户登入或连结游戏服务）、帐户及交易讯息等。因业务经营之必要我们可能将您的个人资料提供给与我们有业务之合作伙伴（例如游戏服务经销商、信用卡公司、苹果(iOS)或Google(Android)或Facebook等社群媒体）或自该等合作伙伴处取得您的个人资料以达到前述特定目的。于一般联机时，服务器会自行记录相关行径，包括您使用联机设备的IP地址、使用时间、使用的操作系统、浏览及点选数据记录等，做为我们增进游戏服务的参考依据，此记录为内部应用。
      </li>
      <li>
        4.
        若您为了寻求技术支持而联系我们，我们也许会向您要求提供其他个人资料或自您使用的装置、设备中取得您使用游戏服务的纪录。
      </li>
      <li>
        5.
        我们可能会透过游戏服务搜集用户行为信息，该等信息无法直接用于辨识特定使用者身份，但将可能被SGA及其合作伙伴用于大数据分析。
      </li>
      <li>
        6.
        倘您并非您所提供的个人资料之当事人，您必须保证该当事人已了解并同意本条款所有内容并授权您提供我们其个人资料。
      </li>
      <li>
        7.
        若您拒绝提供我们所需的个人资料或未提供最新且正确之个人资料，将可能影响您使用游戏服务之权益或导致您无法使用游戏服务。
      </li>
      <h4>三、限制行为能力人相关政策</h4>
      <li>
        1.
        我们提供的游戏服务属于限制级，未满18岁之人不得以任何方式使用或观览。我们不会明知而搜集无行为能力人或限制行为能力人（包括未成年人）的个人资料。如果您是无行为能力人或限制行为能力人，请立即离开本网页并删除您已经下载的游戏服务或相关纪录。基于个人资料最小搜集原则，我们不会主动审核或验证您提供的年龄。
      </li>
      <li>
        2.
        如果您是监护人且您相信我们搜集了受您监护之人的个人资料，您可以透过电子邮件与我们联系要求删除该等个人资料。
      </li>
      <h4>四、数据之保护</h4>
      <li>
        1.
        SGA将尽合理的商业努力，包括行政和技术措施，以保障您的个人资料免受未经授权的接触、使用、变更、披露或销毁。我们的主机均设有防火墙、防毒系统等相关的各项信息安全设备及必要的安全防护措施，加以严格保护网站及您的个人资料，只由经过授权的人员才能接触您的个人资料，相关处理人员皆签有保密合约，如有违反保密义务者，将会受到相关的法律处分或合约责任追究。如我们因业务需要有必要委托业务往来第三方提供服务时，我们亦会严格要求其遵守保密义务，并且采取必要检查程序以确定其将确实遵守。
      </li>
      <li>
        2.
        虽然我们致力于确保我们网络及系统的完整性与安全性，但我们无法保证我们的安全措施能够完全防止第三方黑客非法获得访问此等信息的权限。任何互联网传输方法或电子储存方法都不是100%安全的。因此，我们不能保证您的信息绝对不会受到第三方滥用而产生任何的损失。
      </li>
      <li>
        3.
        我们会在符合本隐私条款及提供您游戏服务之目的及期间内保存及使用您的个人资料。为符合法制、税务、会计需求等，我们可能会将您的数据保存一定的期间。当使用您的个人资料的目的已不复存在时，我们将会删除您的个人资料或将您的个人资料匿名化，使其不具辨别身份之效力。
      </li>
      <h4>五、当事人权利</h4>
      <li>
        1. 您得就您的个人资料以书面方式向我们行使之下列权利，但我们可能因此向您酌收必要之费用：
        <ul className="list__level--2">
          <li>(A) 查询或请求阅览。</li>
          <li>(B) 请求制给复制本。</li>
          <li>(C) 请求补充或更正。</li>
          <li>(D) 请求停止搜集、处理或利用。</li>
          <li>(E) 请求删除。</li>
        </ul>
        <li>2. 为履行法律或契约上义务，我们可能会拒绝您依前项所为之请求。</li>
      </li>
      <h4>六、与第三人共享个人资料之政策</h4>
      <li>
        1.
        我们绝不会提供、交换、出租或出售任何您的个人资料给其他个人、团体、私人企业或公务机关，但有法律依据或合约义务者，不在此限。
      </li>
      <li>
        2. 前项但书之情形包括但不限于：
        <ul className="list__level--2">
          <li>(A) 经由您书面同意。</li>
          <li>(B) 法律明文规定。</li>
          <li>(C) 为维护国家安全或增进公共利益。</li>
          <li>(D) 为免除您生命、身体、自由或财产上之危险。</li>
          <li>(E) 为防止他人权益之重大危害。</li>
          <li>(F) 其他有利于您的权益之情形。</li>
        </ul>
      </li>
      <li>
        3.
        SGA的网页或应用程序中可能提供第三方网站或应用程序的链接，您可经由该连结点选进入其他网站，但该连结网站不适用本条款，您必须参考该链接网站或应用程序的隐私权保护政策。
      </li>
      <h4>七、隐私权保护政策之修正</h4>
      本条款将因应需求随时进行修正或更新，修正后的条款将在游戏开始时显示。请您定期阅读和遵守本条款最新版本以充分保障您的权利。如您继续使用我们所提供的游戏服务，即表示您同意本条款之最新版本。
      <h4>八、语言</h4>
      如中文版本和其他语言的版本有不一致的地方，一切皆以中文版本为准。
      <h4>九、联络方式</h4>
      如果您对于本条款或SGA的隐私保护政策或行为有任何疑问，欢迎透过电子邮件service@sg-arts.com与我们联系。
      <p>最后更新日期：2020年09月16日</p>
    </div>
  );
};

export default PrivacyPolicyZHHans;
